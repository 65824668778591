export const PAGE_LIMIT = 20

export const baseUrl = 'https://english.liaozhishi.com'
export const ossUrl = 'https://jingqi.liaozhishi.com/'
export const bookCoverUrl = ossUrl + '/english/book/covers/'
export const audioUrl = baseUrl + '/audio'
const wordIdAudioUrl = ossUrl + '/english/word/audio/uk/'
const wordIdAudioSlowUrl = ossUrl + '/english/word/audio/uk_slow/'

export const LEARN_WORD_EN = 1001
export const LEARN_WORD_NATURAL = 1002
export const LEARN_WORD_SPEAK = 1003
export const LEARN_WORD_SPELL = 1004

export const formatOssUrl = (url) => {
  if (url.startsWith('http')) {
    return url
  } else {
    return ossUrl + url
  }
}

export const jqImage = (imageUrl) => {
  if (imageUrl && !imageUrl.startsWith('http')) {
    if (imageUrl.charAt(0) === '/') {
      return ossUrl + 'app/liao' + imageUrl
    } else {
      return ossUrl + 'app/liao/' + imageUrl
    }
  } else {
    return imageUrl
  }
}

export const getWordAudio = (id, isSlow = false) => {
  return (isSlow ? wordIdAudioSlowUrl : wordIdAudioUrl) + id + '.mp3'
}
