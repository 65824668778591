export const setItem = (key, value) => {
  try {
    const item = JSON.stringify(value)
    localStorage.setItem(key, item)
  } catch (error) {
    console.error('Error setting item to localStorage', error)
  }
}

export const getItem = (key) => {
  try {
    const item = localStorage.getItem(key)
    return item ? JSON.parse(item) : null
  } catch (error) {
    console.error('Error getting item from localStorage', error)
    return null
  }
}

export const removeItem = (key) => {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    console.error('Error removing item from localStorage', error)
  }
}

export const clearStorage = () => {
  try {
    localStorage.clear()
  } catch (error) {
    console.error('Error clearing localStorage', error)
  }
}

export const setItemAsync = (key, value) => {
  return new Promise((resolve, reject) => {
    try {
      const item = JSON.stringify(value)
      localStorage.setItem(key, item)
      resolve()
    } catch (error) {
      reject('Error setting item to localStorage: ' + error.message)
    }
  })
}

export const getItemAsync = (key) => {
  return new Promise((resolve, reject) => {
    try {
      const item = localStorage.getItem(key)
      resolve(item ? JSON.parse(item) : null)
    } catch (error) {
      reject('Error getting item from localStorage: ' + error.message)
    }
  })
}

export const removeItemAsync = (key) => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.removeItem(key)
      resolve()
    } catch (error) {
      reject('Error removing item from localStorage: ' + error.message)
    }
  })
}

export const clearStorageAsync = () => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.clear()
      resolve()
    } catch (error) {
      reject('Error clearing localStorage: ' + error.message)
    }
  })
}
