import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import './index.css'
import 'normalize.css'

import { DialogProvider } from '@/component/dialog/dialogContext'
import App from './App.js'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <DialogProvider>
    <App />
  </DialogProvider>
)

serviceWorkerRegistration.register()
