import { jqImage } from '@/api/url'
import './register_success.scss'

const RegisterSuccess = ({ user }) => {
  return (
    <div className="success-container">
      <img src={jqImage('/static/logo_jq.png')} alt="Logo" className="logo" />
      <h1>注册成功!</h1>
      <div className="info">
        <div className="info-item">
          <label htmlFor="phoneNumber">账户:</label>
          <input
            id="phoneNumber"
            type="text"
            value={user.phone_code}
            readOnly
          />
        </div>
        <div className="info-item">
          <label htmlFor="password">密码:</label>
          <input id="password" type="text" value={user.password} readOnly />
        </div>
      </div>
      <div className="reminder">
        <p>请扫描书里（比如第1页）</p>
        <p>右上角“扫码看课”二维码</p>
        <p>用以上信息进行登录，即可看课!</p>
      </div>
    </div>
  )
}

export default RegisterSuccess
