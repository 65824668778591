// DialogContext.js
import React, { createContext, useState, useContext, useCallback } from 'react'
import GlobalDialog from './globalDialog'

const DialogContext = createContext()

export const DialogProvider = ({ children }) => {
  const [dialogConfig, setDialogConfig] = useState({
    isOpen: false,
    title: '',
    content: null,
  })

  const openDialog = useCallback((content, title = '鲸奇英语') => {
    setDialogConfig({ isOpen: true, title, content })
  }, [])

  const closeDialog = useCallback(() => {
    setDialogConfig({ isOpen: false, title: '', content: null })
  }, [])

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <GlobalDialog
        isOpen={dialogConfig.isOpen}
        onClose={closeDialog}
        title={dialogConfig.title}
      >
        {dialogConfig.content}
      </GlobalDialog>
    </DialogContext.Provider>
  )
}

export const useDialog = () => useContext(DialogContext)
