import React, { useState, useEffect } from 'react'

import { jqImage } from '@/api/url'

import './register.scss'
import { getItemAsync } from '@/utils/storage'

const NoRegister = () => {
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    const init = async () => {
      const storedPhone = await getItemAsync('loginPhone')
      setPhoneNumber(storedPhone)
    }

    init()
  }, [])

  return (
    <div className="register-container">
      <div className="form-content">
        <img src={jqImage('/static/logo_jq.png')} className="logo" alt="Logo" />
        <div className="tip">
          <div>{phoneNumber}</div>

          <div>上次发送的验证码即为您的永久密码</div>
          <div>请查看您的短信历史</div>
        </div>
      </div>
    </div>
  )
}

export default NoRegister
