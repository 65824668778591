import React, { useEffect, useState } from 'react'
import './table.scss'

const SymbolTable = ({ cells, onClickItem }) => {
  const [cellDataList, setCellDataList] = useState([])

  useEffect(() => {
    setCellDataList(cells)
  }, [cells])

  const getCellStyle = (cell) => ({
    gridColumn: `${cell.col + 1} / span ${cell.num}`,
    gridRow: `${cell.row + 1}`,
  })

  const handleCellClick = (clickedCell) => {
    if (clickedCell.active || !clickedCell.name) return

    clickedCell.active = true

    setCellDataList((prevCellDataList) =>
      prevCellDataList.map((cell) => ({
        ...cell,
        active: cell === clickedCell ? true : false,
      }))
    )

    console.log(`####${clickedCell.name}`)
    onClickItem(clickedCell.item)
  }

  return (
    <div className="grid-layout">
      {cellDataList.map((cell, index) => (
        <div
          key={index}
          className={`grid-item ${cell.active ? 'active' : ''}`}
          style={getCellStyle(cell)}
          onClick={() => handleCellClick(cell)}
        >
          {cell.name}
        </div>
      ))}
    </div>
  )
}

export default SymbolTable
