import './unit.scss'
import React, { useState, useEffect } from 'react'
import NavCircle from '@/component/nav/navCircle'
import Loading from '@/component/loading/loading'
import Question from '@/component/question/question'
//下面代码删掉会导致CacheVideoPlayer的视频样式出错，需要找时间解决下
import VideoPlayer from '@/component/video/videoPlayer'
import TVideoPlayer from '@/component/video/tVideoPlayer'
import CacheVideoPlayer from '@/component/video/cacheVideoPlayer'

import { get, post } from '@/api/http'
import { formatOssUrl } from '@/api/url'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const UnitPage = () => {
  const [unit, setUnit] = useState({})
  const [questions, setQuestions] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [params] = useSearchParams()
  const navigate = useNavigate()

  const unitId = params.get('id')

  useEffect(() => {
    if (unitId) {
      const getQuestions = async (id) => {
        setShowLoading(true)
        const res = await post(`liao/v1/exam/paper/get`, {
          is_random: 1,
          deck_ids: id + '',
          subject_code: '3',
          paper_type: 5,
          single_choice_question_num: 100,
          multiple_choice_question_num: 20,
          judge_question_num: 20,
          blank_question_num: 20,
          free_question_num: 20,
        })

        if (res.sections?.length < 1) {
          return
        }

        let questionsList = []
        res.sections.forEach((section) => {
          section.questions.forEach((item) => {
            item.question.question_type = section.question_type
            questionsList = [...questionsList, item]
          })
        })
        setQuestions(questionsList)
        setShowLoading(false)
      }

      const getUnitInfo = async (id) => {
        const unitData = await get(`jq/v1/units/${id}`)
        if (unitData) {
          setUnit(unitData)
          if (unitData.question_ids && unitData.question_ids.length > 0) {
            getQuestions(id)
          }
        }
      }
      getUnitInfo(unitId)
    }
  }, [unitId])

  const videoUrl =
    unit.video?.length > 0 ? formatOssUrl(unit.video[0].video_url) : null
  const hasQuestion = unit.question_ids?.length > 0

  const goBook = () => {
    navigate(`/pages/tiku/unit?bookId=${unit.book_id}`)
  }

  const goTest = () => {
    navigate(`/pages/exam/exam?id=${unit.id}`)
  }

  return (
    <div className="unit-page">
      <NavCircle buttonText="目录" onClick={goBook} />

      {videoUrl && <CacheVideoPlayer videoUrl={videoUrl}></CacheVideoPlayer>}

      {hasQuestion && (
        <div className="content">
          <div className="page-main-scroll">
            {showLoading && <Loading loadingText="获取题库中,请等待..." />}
            {!showLoading && (
              <div className="context-question">
                {questions.map((question, index) => (
                  <div className="question" key={'question' + index}>
                    <Question question={question.question} index={index} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {hasQuestion && (
        <div className="practice-btn" onClick={goTest}>
          练一练
        </div>
      )}
    </div>
  )
}

export default UnitPage
