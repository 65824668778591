import React, { useState, useEffect } from 'react'
import QuestionItem from '@/component/question/question'
import Loading from '@/component/loading/loading'
import { useSearchParams } from 'react-router-dom'
import { post } from '@/api/http'
import './question.scss'
const Question = () => {
  // 设置状态
  const [showLoading, setShowLoading] = useState(true)
  const [question, setQuestion] = useState({})
  const [params] = useSearchParams()
  const questionId = params.get('id')

  // 在组件加载时，如果没有提供 id，则不执行查询
  useEffect(() => {
    const getQuestionInfo = async (id) => {
      setShowLoading(true)
      try {
        const res = await post('liao/v1/question/get', {
          question_id_str: questionId,
        })
        setQuestion(res.question)
      } catch (err) {
        console.error(err)
      } finally {
        setShowLoading(false)
      }
    }
    getQuestionInfo(questionId)
  }, [questionId])

  // 根据 loading 状态显示组件
  return (
    <div>
      {showLoading ? (
        <Loading loadingText="获取题库中,请等待..." />
      ) : (
        <QuestionItem question={question} />
      )}
    </div>
  )
}

export default Question
