import React from 'react'
import Modal from 'react-modal'
import './globalDialog.scss'

Modal.setAppElement('#root') // 确保对话框对屏幕阅读器友好

const GlobalDialog = ({ isOpen, onClose, title, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={title}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <h2 className="modal-title">{title}</h2>
      <div>{children}</div>
      <button className="modal-close-button" onClick={onClose}>
        确定
      </button>
    </Modal>
  )
}

export default GlobalDialog
