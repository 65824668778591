import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AudioPlayer from '@/component/audio/audioPlayer'
import { formattedText } from '@/utils/tool'
import { ossUrl } from '@/api/url'

import './question.scss'

const Question = ({ question, index = 0 }) => {
  const [showAnswer, setShowAnswer] = useState(false)
  const [imageHeight, setImageHeight] = useState('50px')

  const formattedUrl = (url) => {
    if (url.length <= 0) return url
    if (url.startsWith('http')) {
      return url
    } else {
      return ossUrl + url
    }
  }

  const imageLoad = (event) => {
    const height = event.target.height
    setImageHeight(height + 'px')
  }

  return (
    <div className="question-container">
      <div className="subject">
        <div className="subject-left">
          <span>{index + 1}、</span>
          <div
            dangerouslySetInnerHTML={{
              __html: formattedText(question.question),
            }}
            style={{ fontSize: '16px' }}
          ></div>
        </div>
        {question.question_type === 4 && (
          <span className="enclosed">(&nbsp;&nbsp;&nbsp;)</span>
        )}
      </div>

      {question.question_source && (
        <div className="audio-item">
          <AudioPlayer audioSrc={formattedUrl(question.question_source)} />
        </div>
      )}

      {question.single_choice && question.single_choice.options.length > 0 && (
        <div className="subject-options">
          {question.single_choice.options.map((single_choice) => (
            <div className="option" key={single_choice.option}>
              <span>{single_choice.option}.</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: formattedText(single_choice.answer),
                }}
              ></span>
            </div>
          ))}
        </div>
      )}

      {question.multiple_choice &&
        question.multiple_choice.options.length > 0 && (
          <div className="subject-options">
            {question.multiple_choice.options.map((multiple_choice) => (
              <div className="option" key={multiple_choice.option}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: formattedText(multiple_choice.option),
                  }}
                ></span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: formattedText(multiple_choice.answer),
                  }}
                ></span>
              </div>
            ))}
          </div>
        )}

      {question.question_type === 6 && question.sublist && (
        <div className="zonghe">
          {question.sublist.map((subItem, subIndex) => (
            <div key={subItem.content}>
              <div className="sub-content">
                <span style={{ marginRight: '5px' }}>({subIndex + 1})</span>
                <span
                  style={{ display: 'inline-block' }}
                  dangerouslySetInnerHTML={{
                    __html: formattedText(subItem.content),
                  }}
                ></span>
                {subItem.question_type === 4 && (
                  <span className="enclosed">(&nbsp;&nbsp;&nbsp;)</span>
                )}
              </div>
              {subItem.single_choice && subItem.single_choice.options && (
                <div className="subject-options">
                  {subItem.single_choice.options.map((single_choice) => (
                    <div className="option" key={single_choice.option}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formattedText(single_choice.option),
                        }}
                      ></span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formattedText(single_choice.answer),
                        }}
                      ></span>
                    </div>
                  ))}
                </div>
              )}
              {subItem.multiple_choice && subItem.multiple_choice.options && (
                <div className="subject-options">
                  {subItem.multiple_choice.options.map((multiple_choice) => (
                    <div className="option" key={multiple_choice.option}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formattedText(multiple_choice.option),
                        }}
                      ></span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formattedText(multiple_choice.answer),
                        }}
                      ></span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="answer-parse-view">
        <p className="answer-parse" onClick={() => setShowAnswer(!showAnswer)}>
          {showAnswer ? '收起' : '答案解析'}
        </p>
        {showAnswer && (
          <div className="content-of-the-answer">
            <div className="answer">
              <span>[答案] </span>
              {question.question_type === 1 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: formattedText(question.single_choice.right_option),
                  }}
                ></div>
              )}
              {question.question_type === 2 && (
                <div>
                  {question.multiple_choice.right_options.map((itm) => (
                    <div
                      key={itm}
                      style={{ display: 'inline-block' }}
                      dangerouslySetInnerHTML={{ __html: formattedText(itm) }}
                    ></div>
                  ))}
                </div>
              )}
              {question.question_type === 4 && (
                <div>{question.right_select === 1 ? '对' : '错'}</div>
              )}
              {question.question_type === 3 && (
                <div>
                  {question.right_answer.map((itm, ind) => (
                    <span
                      key={ind}
                      style={{ marginRight: '5px' }}
                      dangerouslySetInnerHTML={{
                        __html: formattedText(itm[0]),
                      }}
                    ></span>
                  ))}
                </div>
              )}
              {question.question_type === 5 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: formattedText(question.free_answer[0][0]),
                  }}
                ></div>
              )}
              {question.question_type === 6 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: formattedText(question.comprehensive_answer),
                  }}
                ></div>
              )}
            </div>
            <div className="parsing">
              <div className="tag">[解析] </div>
              {question.explanation && question.explanation.length > 0 && (
                <div className="explanation">{question.explanation}</div>
              )}
              {question.explanation_url &&
                question.explanation_url.length > 0 && (
                  <div className="explanation-image">
                    <img
                      className="image"
                      src={formattedUrl(question.explanation_url)}
                      alt=""
                      style={{ height: imageHeight, width: 'auto' }}
                      onLoad={imageLoad}
                    />
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
}

export default Question
