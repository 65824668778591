// Loading.js
import React from 'react'
import { jqImage } from '@/api/url'
import './loading.scss'

const Loading = ({ loadingText = '请等待...' }) => {
  return (
    <div className="loading">
      <img
        className="img"
        src={jqImage('/static/app/loading.gif')}
        alt="Loading"
      />
      <div className="text">{loadingText}</div>
    </div>
  )
}

export default Loading
