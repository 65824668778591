import { ossUrl } from '@/api/url'

//格式化HTML格式
export const formattedText = (text) => {
  const replacedText = text
    .replace('question-type', 'span')
    .replace(/<\/question-type>/g, '</span>    ')
    .replaceAll('question_prefix', ossUrl)
    .trim()
  return replacedText
}

// 改变时间格式(0=>00)
const change_single = (data) => {
  if (data >= 10) {
    return `${data}`
  } else {
    return `0${data}`
  }
}

export const changeTime = (data, type) => {
  // type决定返回的格式
  // 默认==> 00:00:00
  // 1==> 00:00
  data = parseInt(data)
  let return_hour = '00'
  let return_min = '00'
  let return_s = '00'
  if (type === 1) {
    if (data >= 60) {
      const min = parseInt(data / 60)
      const s = data % 60
      return_min = change_single(min)
      return_s = change_single(s)
      return `${return_min}:${return_s}`
    } else {
      const s = data % 60
      return_s = change_single(s)
      return `${return_min}:${return_s}`
    }
  }
  if (data >= 60) {
    let s = data % 60
    let min = parseInt(data / 60)
    let hour = 0
    if (min >= 60) {
      hour = parseInt(min / 60)
      min = parseInt(min % 60)
    }
    return_hour = change_single(hour)
    return_min = change_single(min)
    return_s = change_single(s)
  } else {
    let s = data
    return_s = change_single(s)
  }
  return `${return_hour}:${return_min}:${return_s}`
}
