import { useState, useEffect } from 'react'
import { getItemAsync, setItemAsync, removeItemAsync } from '@/utils/storage'

const useCaptchaTimer = (initialTime = 120) => {
  const [captchaTime, setCaptchaTime] = useState(initialTime)
  const [number, setNumber] = useState(0)
  let timer

  const startCountdown = () => {
    clearInterval(timer)
    timer = setInterval(() => {
      setCaptchaTime((prevTime) => {
        if (prevTime > 0) return prevTime - 1
        clearInterval(timer)
        removeItemAsync('endTime')
        return initialTime
      })
    }, 1000)
  }

  useEffect(() => {
    const storedEndTime = async () => {
      const endTime = await getItemAsync('endTime')
      if (endTime && endTime > Date.now()) {
        setCaptchaTime(Math.ceil((endTime - Date.now()) / 1000))
        startCountdown()
      }
    }

    storedEndTime()

    return () => clearInterval(timer)
  }, [])

  const resetCaptchaTime = async () => {
    setNumber(number + 1)
    setCaptchaTime(initialTime)
    startCountdown()
    await setItemAsync('endTime', Date.now() + 1000 * initialTime)
  }

  return { captchaTime, resetCaptchaTime, number }
}

export default useCaptchaTimer
