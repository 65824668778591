import React, { useState, useEffect } from 'react'
import { createHashRouter, RouterProvider } from 'react-router-dom'
import Login from '@/pages/login/login'
import NoRegister from '@/pages/register/no_register'
import RegisterAnti from '@/pages/register_anti/register'
import Home from '@/pages/home/home'
import Course from '@/pages/course/course'
import Unit from '@/pages/unit/unit'
import Exam from '@/pages/exam/exam'
import ExamResult from '@/pages/exam/result'

import Question from '@/pages/question/question'
import Phonetic from '@/pages/phonetic/phonetic'
import { useDialog } from '@/component/dialog/dialogContext'
import { setDialogInstance } from '@/component/dialog/dialogController'
import { initHttpParams } from '@/api/http'
import { setItemAsync } from '@/utils/storage'
const router = createHashRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/pages/register/index',
    element: <NoRegister />,
  },
  {
    path: '/pages/register_anti/index',
    element: <RegisterAnti />,
  },
  {
    path: '/pages/login/index',
    element: <Login />,
  },
  {
    path: '/pages/tiku/unit',
    element: <Course />,
  },
  {
    path: '/pages/tiku/learn',
    element: <Unit />,
  },
  {
    path: 'pages/units/video',
    element: <Unit />,
  },
  {
    path: 'pages/tiku/question',
    element: <Question />,
  },
  {
    path: '/pages/phonetic/index',
    element: <Phonetic />,
  },
  {
    path: '/pages/exam/exam',
    element: <Exam />,
  },
  {
    path: '/pages/exam/result',
    element: <ExamResult />,
  },
])

const AppWrapper = () => {
  const dialog = useDialog()
  const [isInit, setIsInit] = useState(false)

  useEffect(() => {
    setDialogInstance(dialog.openDialog)
  }, [dialog])

  useEffect(() => {
    const initialize = async () => {
      console.log('initHttpParams...')
      const hasLogin = await initHttpParams()
      //没有登录的用户直接跳转到
      // 获取当前页面路由信息
      const currentPage = window.location.hash
      if (
        !hasLogin &&
        !currentPage?.includes('login') &&
        !currentPage?.includes('register')
      ) {
        // 存储当前页面路由信息到 localStorage
        await setItemAsync('redirectFrom', currentPage)

        window.location = '#/pages/login/index'
      }

      setIsInit(true)
    }

    initialize()

    console.log('App Show')

    return () => {
      console.log('App Hide')
    }
  }, [])

  return isInit && <RouterProvider router={router} />
}

export default AppWrapper
