import React, { useState, useEffect, useRef } from 'react'

const VideoPlayer = ({ videoUrl }) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [videoSrc, setVideoSrc] = useState('')
  const [db, setDb] = useState(null)
  const [showStartBtn, setShowStartBtn] = useState(false)

  const abortController = useRef(null)

  useEffect(() => {
    const initIndexedDB = async () => {
      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open('VideoCacheDB', 1)

        request.onupgradeneeded = (event) => {
          const db = event.target.result
          if (!db.objectStoreNames.contains('videos')) {
            db.createObjectStore('videos', { keyPath: 'url' })
          }
        }

        request.onsuccess = (event) => {
          setDb(event.target.result)
          resolve()
        }

        request.onerror = (event) => {
          console.error('IndexedDB error:', event)
          reject(event)
        }
      })
    }

    initIndexedDB()

    return () => {
      if (abortController.current) {
        abortController.current.abort()
        abortController.current = null
      }
    }
  }, [])

  useEffect(() => {
    const checkVideoCache = async () => {
      if (!db) return

      const transaction = db.transaction(['videos'], 'readonly')
      const store = transaction.objectStore('videos')
      const request = store.get(videoUrl)

      return new Promise((resolve, reject) => {
        request.onsuccess = (event) => {
          const data = event.target.result
          if (data?.blob) {
            const blob = data.blob
            setVideoSrc(URL.createObjectURL(blob))
            console.log('Video loaded from cache')
          } else {
            setShowStartBtn(true)
          }
          resolve()
        }

        request.onerror = (event) => {
          console.error('Error fetching video from IndexedDB:', event)
          reject(event)
        }
      })
    }

    checkVideoCache()
  }, [videoUrl, db])

  const handleVideoError = (event) => {
    console.error('Error playing video:', event)
    alert('无法播放视频，请确保视频格式和编码兼容')
  }

  const startDownload = async () => {
    const saveVideoToCache = async (blob) => {
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(['videos'], 'readwrite')
        const store = transaction.objectStore('videos')
        const request = store.put({ url: videoUrl, blob })

        request.onsuccess = () => {
          console.log('Video cached successfully')
          resolve()
        }

        request.onerror = (event) => {
          console.error('Error saving video to IndexedDB:', event)
          reject(event)
        }
      })
    }

    const downloadVideo = async () => {
      setIsDownloading(true)
      setDownloadProgress(0)

      try {
        abortController.current = new AbortController()
        const response = await fetch(videoUrl, {
          signal: abortController.current.signal,
        })
        const reader = response.body.getReader()
        const chunks = []
        let receivedLength = 0
        const contentLength = +response.headers.get('Content-Length') || 0

        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          chunks.push(value)
          receivedLength += value.length
          setDownloadProgress(
            Math.floor((receivedLength / contentLength) * 100)
          )
        }

        const blob = new Blob(chunks, {
          type: response.headers.get('Content-Type') || 'video/mp4',
        })
        await saveVideoToCache(blob)
        setVideoSrc(URL.createObjectURL(blob))

        console.log('Video downloaded and cached')
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Video download aborted')
        } else {
          console.error('Error downloading video:', error)
        }
      } finally {
        setIsDownloading(false)
      }
    }

    setShowStartBtn(false)
    downloadVideo()
  }

  return (
    <div className="c-video-container">
      {showStartBtn && (
        <button className="downloadBtn" onClick={startDownload}>
          播放视频
        </button>
      )}
      {!showStartBtn && isDownloading && (
        <div className="loading-container">
          <div className="loading-circle"></div>
          <p className="loading-percent">{downloadProgress}%</p>
          <p className="loading-text">视频缓存中，请等待...</p>
        </div>
      )}
      {!showStartBtn && !isDownloading && videoSrc && (
        <video
          className="video-player"
          src={videoSrc}
          controls
          onError={handleVideoError}
          style={{ objectFit: 'fill' }}
          controlsList="nodownload"
          playsInline
          webkit-playsinline=""
          x5-playsinline=""
          autoPlay
        />
      )}
    </div>
  )
}

export default VideoPlayer
