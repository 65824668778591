import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { get } from '@/api/http'
import { Link } from 'react-router-dom'
import './course.scss'
import NavCircle from '@/component/nav/navCircle'
import { getItem, setItem } from '@/utils/storage'

function updateBookIds(bookId) {
  const bookIdsString = getItem('bookIds')

  // 如果 'bookIds' 为空，则初始化为一个空数组
  let bookIds = bookIdsString ? bookIdsString.split(',') : []

  // 检查 bookId 是否已经存在
  if (!bookIds.includes(bookId)) {
    bookIds.push(bookId)
    const updatedBookIdsString = bookIds.join(',')
    setItem('bookIds', updatedBookIdsString)
  }
}

const CourseUnit = () => {
  const [units, setUnits] = useState([])
  const [params] = useSearchParams()
  const bookId = params.get('bookId')
  const bookName = params.get('bookName')

  useEffect(() => {
    if (bookId) {
      updateBookIds(bookId)
      const getUnitsByBook = async (bookId) => {
        const unitsData = await get(`jq/v1/units/book/${bookId}`)
        setUnits(unitsData)
      }
      getUnitsByBook(bookId)
    }
  }, [bookId])

  return (
    <div>
      <NavCircle buttonText="首页" />
      <div className="title">{bookName}</div>
      <ul className="list">
        {units.map((unit, index) => (
          <Link
            key={unit.id}
            className="item"
            to={`/pages/tiku/learn?id=${unit.id}`}
          >
            <span className="index">{index + 1}.</span>
            <span className="name">{unit.name}</span>
          </Link>
        ))}
      </ul>
    </div>
  )
}

export default CourseUnit
