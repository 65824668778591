import React, { useState, useEffect } from 'react'
import QuestionItem from '@/component/question/question'
import './result.scss'
import { useSearchParams } from 'react-router-dom'
import { post } from '@/api/http'
import { jqImage } from '@/api/url'

const ExamResult = () => {
  const [paper, setPaper] = useState(null)
  const [translateSection, setTranslateSection] = useState({})
  const [params] = useSearchParams()
  const examId = params.get('id')

  useEffect(() => {
    const getPaper = async () => {
      const res = await post('liao/v1/exam/detail/get', { exam_id: examId })
      setPaper(res)
      res.detail.sections.forEach((item) => {
        if (item.question_type === 1) {
          setTranslateSection(item)
        }
      })
    }
    getPaper()
  }, [])

  const isAnswerRight = (question) => {
    return (
      question.answer.option === question.question.single_choice.right_option
    )
  }

  const getMyAnswer = (question) => {
    if (!question.answer.option) return '未作答'
    const userSelectOption = question.question.single_choice.options.find(
      (option) => option.option === question.answer.option
    )
    return userSelectOption?.answer
  }

  if (!paper) return null

  return (
    <div className="word-paper-wrap">
      <div className="word-paper">
        <div className="result">
          <img
            className={paper.score >= 100 ? 'success' : 'fail'}
            src={
              paper.score >= 100
                ? jqImage('/static/exam/exercise_success.gif')
                : jqImage('/static/exam/exercise_fail.gif')
            }
            alt="result"
          />
          <div className={`point ${paper.score < 80 ? 'point-fail' : ''}`}>
            {paper.score}分
          </div>
          <div className="point-text">
            {paper.score < 80
              ? '很遗憾，本次测试不合格'
              : '恭喜你，通过本次测试'}
          </div>
          <div className="question-info">
            <span className="tag">答对：</span>
            <span className="content right">{paper.right_count}</span>
            <span className="tag">答错：</span>
            <span className="content wrong">
              {paper.skip_count + paper.wrong_count + paper.timeout_count}
            </span>
          </div>
        </div>
        <div className="main">
          <div className="module-contain">
            {translateSection.questions &&
              translateSection.questions.length > 0 && (
                <div className="translate">
                  <ul>
                    {translateSection.questions.map((question, index) => (
                      <li key={index}>
                        <QuestionItem
                          question={question.question}
                          index={index}
                        />
                        {!isAnswerRight(question) && (
                          <div className="your-answer">
                            <span className="tag">你的作答:</span>
                            <span className="option">
                              {question.answer.option}.
                            </span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getMyAnswer(question),
                              }}
                            ></span>
                          </div>
                        )}
                        <div className="judge">
                          <img
                            className="img"
                            src={
                              isAnswerRight(question)
                                ? jqImage('/static/word/result_right.png')
                                : jqImage('/static/word/result_wrong.png')
                            }
                            alt="judge"
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExamResult
