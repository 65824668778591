import React from 'react'
import AudioPlayer from '@/component/audio/audioPlayer'
import { getWordAudio } from '@/api/url'
import './word.scss'

const WordsList = ({ words }) => {
  const highlightSpecialContent = (symbol) => {
    return symbol.replace(/\[(.*?)\]/g, `<span class="special-color">$1</span>`)
  }

  return (
    <div className="words-container">
      {words.map((word) => (
        <div className="word-item" key={word.w_id}>
          <span
            className="symbol"
            dangerouslySetInnerHTML={{
              __html: highlightSpecialContent(word.symbol),
            }}
          />
          <span className="phonogram">{word.phonogram}</span>
          <span className="word-desc">{word.desc}</span>
          <AudioPlayer audioSrc={getWordAudio(word.w_id)} />
        </div>
      ))}
    </div>
  )
}

export default WordsList
