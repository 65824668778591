import React, { useEffect, useState } from 'react'
import { formattedText } from '@/utils/tool'
import AudioPlayer from '@/component/audio/audioPlayer'
import { jqImage, formatOssUrl } from '@/api/url'
import './choice.scss'

const Choice = ({
  question,
  timeLimit = false,
  onTestChoiceBack,
  onNextQuestion,
}) => {
  const [myAnswer, setMyAnswer] = useState('')
  const [showResult, setShowResult] = useState(false)
  const [countDownTime, setCountDownTime] = useState(10)
  const [countDownTimer, setCountDownTimer] = useState(null)
  const [imageHeight, setImageHeight] = useState('50px')

  const options = question?.single_choice?.options || []
  const rightOption = question?.single_choice?.right_option

  useEffect(() => {
    if (question?.question_id) {
      setMyAnswer('')
      setShowResult(false)
      if (timeLimit) setCountDown()
    }
    return () => clearInterval(countDownTimer)
  }, [question?.question_id])

  const formatted = (text) => formattedText(text)

  const imageLoad = (event) => {
    const { height } = event.target
    setImageHeight(`${height * 1.4}px`)
  }

  const chooseAnswer = (option) => {
    if (myAnswer || showResult) return

    setMyAnswer(option)
    const isRight = option === rightOption
    onTestChoiceBack({
      answer: option,
      result: isRight ? 1 : 2,
      cost_time: 10 - countDownTime,
    })
    playAudio(isRight ? 'right' : 'wrong')
    setShowResult(true)
    clearInterval(countDownTimer)
    setCountDownTime(10)
  }

  const setCountDown = () => {
    clearInterval(countDownTimer)
    setCountDownTime(10)
    setCountDownTimer(
      setInterval(() => {
        setCountDownTime((prevTime) => {
          if (prevTime <= 0) {
            onTestChoiceBack({
              answer: '',
              result: 4,
              cost_time: 10,
            })
            setShowResult(true)
            playAudio('wrong')
            clearInterval(countDownTimer)
            return 0
          }
          return prevTime - 1
        })
      }, 1000)
    )
  }

  const playAudio = (type) => {
    const audioSrc =
      type === 'right'
        ? '/static/publick/right.mp3'
        : '/static/publick/wrong.mp3'
    const audio = new Audio(jqImage(audioSrc))
    audio.play()
  }

  return (
    <div className="choice">
      {question && (
        <>
          {question.question && (
            <div
              className={
                question.source_type === 1001 ? 'question_en' : 'question_zh'
              }
              dangerouslySetInnerHTML={{ __html: formatted(question.question) }}
            />
          )}
          {question.question_source && (
            <div className="audio-item">
              <AudioPlayer audioSrc={formatOssUrl(question.question_source)} />
            </div>
          )}
          {options.length > 0 && (
            <ul className="options">
              {options.map((item) => (
                <li
                  key={item.option}
                  onClick={() => chooseAnswer(item.option)}
                  className={`${showResult && item.option === rightOption ? 'right_choose' : ''} ${showResult && item.option !== rightOption && item.option === myAnswer ? 'wrong_choose' : ''} ${question.source_type === 1002 ? 'en' : ''}`}
                >
                  <div className="option">
                    <span className="tag">{item.option}.</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatted(item.answer),
                      }}
                    />
                  </div>
                  {showResult && (
                    <div className="judge">
                      {item.option === rightOption ? (
                        <img
                          className="img"
                          src={jqImage('/static/word/result_right.png')}
                          alt="Right"
                        />
                      ) : (
                        item.option === myAnswer &&
                        item.option !== rightOption && (
                          <img
                            className="img"
                            src={jqImage('/static/word/result_wrong.png')}
                            alt="Wrong"
                          />
                        )
                      )}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
          {showResult && (
            <div className="parsing">
              <div className="tag">[解析] </div>
              {question.explanation && question.explanation.length > 0 ? (
                <div className="explanation">{question.explanation}</div>
              ) : (
                question.explanation_url && (
                  <div className="explanation-image">
                    <img
                      className="image"
                      src={formatOssUrl(question.explanation_url)}
                      alt=""
                      style={{ height: imageHeight, width: 'auto' }}
                      onLoad={imageLoad}
                    />
                  </div>
                )
              )}
            </div>
          )}
          {showResult && (
            <div className="next" onClick={onNextQuestion}>
              下一题
            </div>
          )}
          {timeLimit && <div className="count-down">{countDownTime}s</div>}
        </>
      )}
    </div>
  )
}

export default Choice
