// dialogController.js

let openDialogInstance = null

export const setDialogInstance = (instance) => {
  openDialogInstance = instance
}

export const openDialog = (title, content) => {
  if (openDialogInstance) {
    openDialogInstance(title, content)
  }
}

export const closeDialog = () => {
  if (openDialogInstance) {
    openDialogInstance()
  }
}
