import React, { useEffect } from 'react'
import './videoPlayer.scss'
import TCPlayer from 'tcplayer.js'
import 'tcplayer.js/dist/tcplayer.min.css'

const TVideoPlayer = ({
  url = 'http://1258598560.vod-qcloud.com/208e4cf3vodcq1258598560/60ee8a031253642701040016022/uRStAOPYXq8A.mp4',
}) => {
  useEffect(() => {
    const player = TCPlayer('player-container-id', {
      sources: [
        {
          src: url,
        },
      ],
      height: 200,
      licenseUrl:
        'https://license.vod2.myqcloud.com/license/v2/1258598560_1/v_cube.license', // license 地址，参考准备工作部分，在视立方控制台申请 license 后可获得 licenseUrl
    })

    // 清理函数
    return () => {
      player.dispose()
    }
  }, [])

  return (
    <div className="t-video-container">
      <video
        id="player-container-id"
        className="responsive-video"
        preload="none"
        x5-playsinline=""
        controlsList="nodownload"
        controls
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        webkit-playsinline="true" /*这个属性是ios 10中设置可以
                           让视频在小窗内播放，也就是不是全屏播放*/
        playsinline="true" /*IOS微信浏览器支持小窗内播放*/
        x-webkit-airplay="allow"
        x5-video-player-type="h5" /*启用H5播放器,是wechat安卓版特性*/
        x5-video-player-fullscreen="true" /*全屏设置，
                           设置为 true 是防止横屏*/
        x5-video-orientation="portraint" /*播放器支付的方向，
        landscape横屏，portraint竖屏，默认值为竖屏*/
      ></video>
    </div>
  )
}

export default TVideoPlayer
