import React, { useState, useRef } from 'react'
import { jqImage } from '@/api/url'
import './audioPlayer.scss'
// 音频组件
const AudioPlayer = ({
  audioSrc,
  imageSrc = '/static/audio.png',
  gifSrc = '/static/audio.gif',
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause()
    } else {
      audioRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  const handleEnded = () => {
    setIsPlaying(false)
  }

  return (
    <div>
      <img
        className="audio-img"
        src={isPlaying ? jqImage(gifSrc) : jqImage(imageSrc)}
        alt="Audio control"
        onClick={handlePlayPause}
        style={{ cursor: 'pointer' }}
      />
      <audio ref={audioRef} src={audioSrc} onEnded={handleEnded} />
    </div>
  )
}

export default AudioPlayer
