import React, { useEffect, useState } from 'react'
import Loading from '@/component/loading/loading'
import Choice from './component/choice'
import { changeTime } from '@/utils/tool'
import { post } from '@/api/http'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import './exam.scss'

const Exam = ({
  word_exam_params = {
    subject_code: '3',
    paper_type: 5, // 试卷类型。8-单词;9-句子;10-语法练一练;4-语法挑战100;5-章节组卷;6-知识点组卷
    book_id: 0,
    deck_ids: '0',
    single_choice_question_num: 20, // 单选题
    question_infos: [
      {
        question_type: 1001, //单词1000代表挑战100，1001代表英汉速记1002汉英速记1003发音矫正1004单词拼写
        question_num: 20, //该类型的数量
      },
    ],
  },
  onClose,
}) => {
  const navigate = useNavigate()
  const [showLoading, setShowLoading] = useState(false)
  const [totalTime, setTotalTime] = useState(0)
  const [resultData, setResultData] = useState({
    totalCount: 0,
    rightCount: 0,
    wrongCount: 0,
    score: 0,
    examId: '',
  })
  const [paper, setPaper] = useState(null)
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(-1)
  const [activeSectionIndex, setActiveSectionIndex] = useState(0)
  const [params] = useSearchParams()
  const unitId = params.get('id')
  word_exam_params.deck_ids = '' + unitId

  let activeQuestion

  if (paper) {
    activeQuestion =
      paper?.sections[activeSectionIndex]?.questions[activeQuestionIndex]
  }

  useEffect(() => {
    const getPaper = async () => {
      setShowLoading(true)
      const res = await post('liao/v1/exam/paper/get', word_exam_params)
      const time = Math.floor(new Date().getTime() / 1000)
      setPaper({ ...res, start_time: time })
    }

    getPaper()
  }, [])

  useEffect(() => {
    let totalTimeTimer

    if (paper && paper.sections?.length > 0) {
      setActiveSectionIndex(0)
      setNextActiveQuestion()
      setResultData((prevData) => ({
        ...prevData,
        examId: paper.exam_id,
        totalCount: paper.question_num,
      }))
      setTimeout(() => {
        setShowLoading(false)

        totalTimeTimer = setInterval(
          () => setTotalTime((prevTime) => prevTime + 1),
          1000
        )
      }, 1000)
    }

    return () => {
      if (totalTimeTimer) {
        clearInterval(totalTimeTimer)
      }
    }
  }, [paper])

  const handIn = async () => {
    const time = Math.floor(new Date().getTime() / 1000)
    const res = await post('liao/v1/exam/paper/hand_in', {
      ...paper,
      end_time: time,
    })
    setResultData((prevData) => ({ ...prevData, score: res.score }))
    navigate(`/pages/exam/result?id=${resultData.examId}`, { replace: true })
  }

  const setNextActiveQuestion = () => {
    if (
      activeQuestionIndex >=
      paper.sections[activeSectionIndex].questions.length - 1
    ) {
      if (activeSectionIndex >= paper.sections.length - 1) {
        handIn()
        return
      }
      setActiveQuestionIndex(0)
      setActiveSectionIndex((prevIndex) => prevIndex + 1)
    } else {
      setActiveQuestionIndex((prevIndex) => prevIndex + 1)
    }
  }

  const testChoiceBack = (data) => {
    activeQuestion.answer = { result: data.result, option: data.answer }
    if (data.result === 1) {
      setResultData((prevData) => ({
        ...prevData,
        rightCount: prevData.rightCount + 1,
      }))
    } else {
      setResultData((prevData) => ({
        ...prevData,
        wrongCount: prevData.wrongCount + 1,
      }))
    }
    answerQuestion(1, data.answer, data.result, data.cost_time)
  }

  const answerQuestion = async (questionType, answer, result, costTime) => {
    const params = {
      book_id: paper.book_id,
      unit_id: paper.unit_id,
      content_id: activeQuestion.question.content_id,
      content_type: 1,
      question_type: questionType,
      question_id: activeQuestion.question.question_id,
      subject_code: '3',
      result,
      cost_time: costTime,
    }

    if (questionType === 1) {
      params.option = answer
    } else if (questionType === 5) {
      params.free_answer = answer
    } else if (questionType === 3) {
      params.answer = answer
    }
    await post('liao/v1/question/answer', params)
  }

  return (
    <div className="exam-wrap">
      {showLoading ? (
        <Loading loadingText="试卷生成中,请等待..." />
      ) : (
        <div className="exam-main">
          <div className="contain">
            {activeQuestion?.question &&
              activeQuestion.question.question_type === 1 && (
                <Choice
                  question={activeQuestion.question}
                  onTestChoiceBack={testChoiceBack}
                  onNextQuestion={setNextActiveQuestion}
                />
              )}
          </div>
          <div className="tip">
            <div className="index">
              {activeQuestionIndex + 1}/{resultData.totalCount}
            </div>
            <div className="right">
              正确: <span className="number">{resultData.rightCount}</span>
            </div>
            <div className="wrong">
              错误: <span className="number">{resultData.wrongCount}</span>
            </div>
            <div className="time">用时：{changeTime(totalTime, 2)}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Exam
