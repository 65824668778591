import React from 'react'
import './navCircle.scss'
import { useNavigate } from 'react-router-dom'

const NavCircle = ({ buttonText = '目录', onClick }) => {
  const navigate = useNavigate()

  // 点击处理函数
  const handleClick = () => {
    if (onClick) {
      onClick()
    } else {
      navigate('/')
    }
  }

  return (
    <div className="catalog-button" onClick={handleClick}>
      <div className="catalog-text">{buttonText}</div>
    </div>
  )
}

export default NavCircle
